*,
*::before,
*::after{
  box-sizing: content-box;
}

.content {
  background: #f5faff;
  padding: 10px 3%;
}

.sidebar {
  background: #fff;
  border-radius: 10px;
}

.sidebar-img {
  max-width: 100%;
  max-height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  color: var(--primary-color);
  font-family: 'CeraProRegular';
}

a {
  text-decoration: none !important;
}

.text-center {
  text-align: center;
}

.main {
  padding-left: 20px;
}

.flex-standard {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-light {
  color: rgba(30, 52, 101, 0.65) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.blue-button {
  position: relative;
  text-align: center;
  background: var(--primary-color);
  border-radius: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: none;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.blue-button:hover {
  opacity: 0.9;
}

.blue-button-outlined {
  background: #fff;
  border-radius: 6px;
  margin-top: 12px;
  margin-bottom: 12px;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.blue-button-outlined:hover {
  background: #f5faff;
}

.cancel-button {
  background: #fff;
  border-radius: 6px;
  margin-top: 1em;
  margin-bottom: 1em;
  border: 0;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.cancel-button:hover {
  background: #f5faff;
}

.main-content {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.paper {
  border: 1px solid rgba(30, 52, 101, 0.1);
  border-radius: 10px;
}

.rounded-xl {
  border-radius: 10px;
}

.MuiTableCell-root, .MuiTableHead-root {
  border: none !important;
  padding: 10px 0 !important;
}

.MuiTableCell-root:last-child {
  padding-right: 20px !important;
}

.MuiTableCell-root:first-child {
  padding-left: 10px !important;
}

.MuiTable-fixedFooter {
  opacity: 0.65;
}

.row-hover:hover .MuiTableCell-body {
  opacity: 0.65;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.MuiTableCell-body {
  color: rgba(30, 52, 101, 1) !important;
}

.MuiGrid-root:focus {
  outline: none;
}

.MuiTableHead-root .MuiTableCell-root {
  font-size: 12px;
  vertical-align: bottom;
}

.MuiTimelineContent-root {
  padding-top: 0 !important;
}

.MuiTimelineOppositeContent-root {
  padding-top: 0 !important;
}

.MuiTimelineConnector-root {
  width: 4px !important;
  background-color: rgba(30, 52, 101, 1) !important;
}

.MuiTimelineDot-root {
  padding: 10px !important;
  margin: 0 !important;
}

.MuiTimelineDot-defaultGrey {
  border-style: solid;
  border-width: 2px;
  background-color: rgba(30, 52, 101, 1) !important;
  border-color: rgba(30, 52, 101, 1) !important;
  box-shadow: none !important;
}

.MuiTimelineDot-outlinedGrey {
  border-color: rgba(30, 52, 101, 1) !important;
  box-shadow: none !important;
}

/* Works on Firefox */

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(30, 52, 101, 0.75) rgba(30, 52, 101, 0.1);
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: rgba(30, 52, 101, 0.1);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(30, 52, 101, 0.75);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: rgba(30, 52, 101, 1);
  border-radius: 20px;
}

.finance-donut {
  cursor: pointer;
  padding: 20px;
  transition: all 0.2s ease-in-out;
}

.finance-donut:hover {
  background-color: rgba(30, 52, 101, 0.1);
}

.MuiAccordion-root {
  text-align: center;
}

.MuiAccordionSummary-expandIcon {
  margin-left: auto !important;
}

.MuiAccordionSummary-content {
  position: absolute;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.MuiAccordionDetails-root {
  padding: 0px !important;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.topbar {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 10px 2%;
}

.footer {
  position: absolute;
  bottom: 0;
  height: 30px;
  width: 100%;
  background-color: #f3f0f0;
}

.pointer {
  cursor: pointer;
}

@media not all and (min-resolution: 0.001dpcm) {
  /* only run on Safari */
  /* fixed topbar not showing in Safari */
  @supports (-webkit-appearance: none) {
    .content {
      position: static !important;
      margin-top: 50px;
    }
  }
}

.custom-form-table>tr>td {
  padding: 5px;
}

.navbar-text {
  font-family: 'CeraProRegular';
}

.transaction-list::-webkit-scrollbar {
  display: none;
}

.transaction-list {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.hide-scroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.shadow-selected {
  text-shadow: 0 0 3px #FFFFFF;
}

.shadow-unselected {
  text-shadow: 0 0 3px #000000;
  color: #FFFFFF;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.editor-class {
  border-radius: 5px;
  height: 200px;
  padding: 0 5px;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.paper-shadow {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.bottom-bar {
  margin-bottom: 22px !important;
}
