:root {
  --main-color: #FFFB1F;
  --primary-color: #000000;
  --secondary-color: #8B95AB;
  --tertiary-color: #8B95AB;
  --active-color: #000000;
  --container-color: #F5FAFF;
  --background-color: #F6F6F8;
  --dark-color: #2C2400;
  --light-color: #FEFEFE;
}